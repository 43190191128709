<template>
	<div id="404">
		<header>
			<div class="container">
				<router-link to="/">
					<img src="@/assets/terminal.svg" alt="" />
				</router-link>
			</div>
		</header>

		<main class="container">
			<div class="text-center">
				<i data-v-19fb68dd="" class="fas fa-unlink"></i>

				<h1 class="mb-20">404: Síðan finnist ikki</h1>

				<p>Síðan, sum tú leitar eftir, finnist ikki. Um tú heldur, ein feilur er hendur, kontakta support hjá terimal.fo, og so skulu vit hjálpa tær við at loysa trupuleikan.</p>

				<a href="/" class="btn mt-30">Far aftur til forsíðuna</a>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	name: 'Client404',

	components: {},
};
</script>

<style lang="scss" scoped>
i {
	font-size: 32px;
	margin-bottom: 24px;
}

p {
	max-width: 640px;
	margin: 0 auto;
	font-size: 15px;
	line-height: 1.5;
}

.btn {
	display: inline-block;
}
</style>
